<template>
    <div id="login_bg" v-if="loginPopup" @click="loginPopup = false">
        <div class="login" @click.stop="loginPopup = true">
            <img class="login1" src="../assets/image/login.png" alt="">
            <div class="login2">
                <div class="login_title">{{ typeIndex == 0 ? '验证码登录' : '微信登录' }}</div>
                <!-- 验证码登录 -->
                <div class="login_phone" v-if="typeIndex == 0">
                    <div class="login_phone1">
                        <div>+86</div>
                        <input class="login_input1" placeholder="请输入手机号" maxlength="11" type="number" v-model="phone">
                    </div>
                    <div class="login_phone2">
                        <input class="login_input2" v-model="code" placeholder="请输入验证码" type="number">
                        <!-- <div class="login_phone3" @click="getCode">{{ codeTime ? `${time}s` : '发送验证码' }}</div> -->
                        <div id="get-code" class="login_phone3" v-if="!codeTime">
                            发送验证码
                        </div>
                        <div class="login_phone3" v-else>{{ time }} s</div>
                    </div>

                    <div class="login_phone4" @click="loginPhone">登录</div>
                </div>

                <!-- 微信登录 -->
                <div class="login_wx" v-if="typeIndex == 1">
                    <div class="login_wx1" v-if="!agreement">
                        <div class="login_wx2">请同意《用户协议》和《隐私权政策》</div>
                        <div class="login_wx3" @click="agreement = true">同意协议</div>
                    </div>
                    <WxLogin :appid="appId" :scope="scope" :redirect_uri="redirect_uri" :href="href"></WxLogin>
                </div>


                <div class="login_agreement">
                    <div class="login_agreement1" @click="agreement = !agreement">
                        <img :src="agreement ? require('@/assets/image/login4.png') : require('@/assets/image/login3.png')"
                            alt="">
                    </div>
                    <div @click="agreement = !agreement">
                        登录代表同意 <span @click.stop="toagreement(0)">《用户协议》</span> 和<span
                            @click.stop="toagreement(1)">《隐私权政策》</span>
                    </div>
                </div>
                <div class="login_bottom">

                    <div class="login_other">
                        <div class="login_other1">其他登录方式</div>
                        <div class="login_other2" v-for="(item, i) in otherList" :key="i" v-if="typeIndex != i"
                            @click="other">
                            <img :src="item.icon" alt="">
                            <div class="login_other3">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import data from "@/assets/data";
import api from "@/api/api";
import WxLogin from "@/components/WxLogin.vue";
// import { Base64 } from "js-base64";
export default {
    components: {
        WxLogin
    },
    data() {
        return {
            appId: "wxc8cb7d946e617297",
            scope: "snsapi_login",
            redirect_uri: "https://pc.hbsry.com",
            loginPopup: false,
            typeIndex: 0, //切换登录方式
            otherList: data.data.otherList, //登录方式
            agreement: false, //同意用户协议
            codeTime: false, //是否倒计时
            time: 60, //倒计时
            phone: '',
            code: '',
            href: "data:text/css;base64," + data.data.ewm,
        };
    },
    created() {
        if (window.location.search.substring(6).split("&")[0]) {
            this.loginWx(window.location.search.substring(6).split("&")[0]);
        }
    },
    methods: {
        show() {
            this.loginPopup = true
            this.$nextTick(() => {
                // 弹出式
                initAliyunCaptcha({
                    SceneId: '1xbwlboq', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
                    prefix: '19t15o', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
                    mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
                    element: '#captcha-element', //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
                    button: '#get-code', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
                    captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
                    onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
                    getInstance: getInstance, // 绑定验证码实例函数，无需修改
                    slideStyle: {
                        width: 300,
                        height: 40,
                    }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
                    language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
                    region: 'cn' //验证码示例所属地区，支持中国内地（cn）、新加坡（sgp）
                })

                // 绑定验证码实例函数。该函数为固定写法，无需修改
                function getInstance(instance) {
                    this.captcha = instance;
                }

                const that = this
                async function captchaVerifyCallback(captchaVerifyParam) {
                    const verifyResult = {}
                    verifyResult.captchaResult = await that.getCode(captchaVerifyParam)
                    return verifyResult;
                }

                // 业务请求验证结果回调函数
                function onBizResultCallback(bizResult) {
                    console.log(bizResult)
                }
            })

        },
        // 获取验证码
        async getCode(verifyParam) {
            if (!(/^1[3456789]\d{9}$/.test(this.phone))) {
                this.$message({
                    message: "手机号码不合法，请重新输入",
                    type: "warning",
                });
                return
            }
            let that = this;
            const res = await api.GetCode({
                phone: this.phone,
                verifyParam: verifyParam
            })
            if (res.code == 200) {
                this.$message({
                    message: "验证码发送成功！",
                    type: "success",
                });
                this.codeTime = true;
            }
            const result = res.data == null || res.data
            if (200 == res.code && result) {
                const interval = setInterval(function () {
                    if (that.time === 1) {
                        clearInterval(interval);
                        that.codeTime = false;
                    } else {
                        // console.log(that.time)
                        that.time--;
                    }
                }, 1000);
            }

            return result
        },
        other() {
            if (this.typeIndex == 0) {
                this.typeIndex = 1
            } else if (this.typeIndex == 1) {
                this.typeIndex = 0
            }
        },
        toagreement(i) {
            if (i == 0) {
                window.open("https://ai.hbsry.com/protocol/yhxy.html");
            }
            if (i == 1) {
                window.open("https://ai.hbsry.com/protocol/ysxy.html");
            }
        },
        loginPhone() {
            if (!(/^1[3456789]\d{9}$/.test(this.phone))) {
                this.$message({
                    message: "手机号码不合法，请重新输入",
                    type: "warning",
                });
                return
            }
            if (this.code == '') {
                this.$message({
                    message: "请输入验证码",
                    type: "warning",
                });
                return
            }

            if (!this.agreement) {
                this.$message({
                    message: '请同意《用户协议》和《隐私权政策》',
                    type: 'warning'
                });
                return
            }
            let data = {
                phone: this.phone,
                code: this.code,
            };
            api.loginPhone(data).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        message: "登录成功！",
                        type: "success",
                    });
                    localStorage.setItem("token_key", res.data.access_token);
                    this.visible = false;
                    // 关闭弹框
                    this.loginPopup = false;
                    window.location.reload();
                } else {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            });
        },
        loginWx(code) {
            api.loginWx({
                code: code,
            }).then((res) => {
                //   console.log(res)
                if (res.code == 200) {
                    this.$message({
                        message: "登录成功！",
                        type: "success",
                    });

                    // 登录后删除code
                    var currentUrl = window.location.href; // 获取当前URL
                    // 构建一个新的URL，通过分割URL并过滤掉code参数
                    var newUrl = currentUrl.split("?")[0]; // 获取不包含查询参数的URL部分
                    window.history.replaceState({}, "", newUrl); // 使用新的URL替换当前历史记录

                    localStorage.setItem("token_key", res.data.access_token);
                }
                // 关闭弹框
                this.loginPopup = false;
                window.location.reload();
            });
        },
    }
}
</script>

<style>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

#login_bg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000038;
    z-index: 1000;
}

.login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 800px;
}

.login1 {
    width: 280px;
    height: 560px;
}

.login2 {
    width: 540px;
    background-color: #FFFFFF;
    height: 560px;
    border-radius: 0 1.3rem 1.3rem 0;
    padding-top: 48px;
}

.login_title {
    width: "93%";
    color: '#333333';
    font-weight: 700;
    font-size: 24px;
    text-align: center;
}

.login_bottom {
    position: absolute;
    bottom: 20px;
}

.login_other {
    display: flex;
    flex-flow: column;
    width: 540px;
    align-items: center;
    margin-top: 10px;
}

.login_other1 {
    font-size: 12px;
    color: #666666;
}

.login_other2 {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.login_other2 img {
    width: 40px;
    margin: 3px 0;
}

.login_other3 {
    width: 100px;
    text-align: center;
    font-size: 14px;
    color: #333333;
}

.login_agreement {
    width: 540px;
    text-align: center;
    color: #999999;
    font-size: 14px;
    display: flex;
    justify-content: center;
    cursor: default;
    margin-top: 40px;
}

.login_agreement1 img {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    margin-top: 3px;
}

.login_agreement span {
    color: #409EFF;
}

.login_phone {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 5vh;
}

.login_phone1 {
    width: 360px;
    height: 56px;
    background-color: #F5F5F5;
    border-radius: 8px;
    display: flex;
    line-height: 56px;
    color: #333333;
    padding: 0 20px;
    font-size: 16px;
}

.login_input1 {
    border: none;
    background-color: #F5F5F5;
    padding-left: 20px;
    outline: none;
    width: 340px;
    font-size: 16px;
}

.login_phone2 {
    display: flex;
    margin-top: 24px;
}

.login_input2 {
    width: 220px;
    height: 56px;
    border: none;
    background-color: #F5F5F5;
    border-radius: 8px;
    margin-right: 8px;
    padding-left: 20px;
    outline: none;
    font-size: 16px;
}

.login_phone3 {
    width: 112px;
    height: 56px;
    line-height: 56px;
    font-size: 16px;
    text-align: center;
    background-color: #409EFF;
    color: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
}

.login_phone4 {
    width: 360px;
    height: 56px;
    background-color: #409EFF;
    line-height: 56px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    border-radius: 56px;
    font-weight: bold;
    margin-top: 40px;
    cursor: pointer;
}

.login_wx {
    width: 300px;
    height: 300px;
    background-color: #FFFFFF;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    position: relative;
}

.login_wx1 {
    width: 280px;
    height: 300px;
    background-color: #333333;
    position: absolute;
    background-color: #0000003f;
    color: #FFFFFF;
    font-size: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 20px;
    cursor: default;
}

.login_wx2 {
    width: 80%;
    text-align: center;
}

.login_wx3 {
    width: 80%;
    height: 30px;
    font-size: 15px;
    color: #000000;
    text-align: center;
    line-height: 30px;
    background: linear-gradient(90deg, #F9D6A8 0%, #F0B675 100%);
    border-radius: 2rem;
    margin-top: 5px;
    cursor: default;
}


/* .impowerBox .qrcode{
    width: 100px !important;
} */
</style>