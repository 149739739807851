<template>
    <div class="vip_major">
        <div class="vip_major1">
            <img v-if="!login" :src="headImg[1]" alt="">
            <img v-else :src="userinfo.vipType == 'common' ? headImg[0] :
                    userinfo.vipType == 'perpetual' ? headImg[7] :
                        userinfo.vipType == 'month' ? headImg[3] :
                            userinfo.vipType == 'year' ? headImg[5] : headImg[0]" alt="">
            <div v-if="!login" class="vip_major3 vip_major4" @click="toLogin">未登录</div>
            <div v-else class="vip_major2" >
                <div class="vip_major3">
                    {{ userinfo.nickname }}
                </div>
                <div class="vip_major3 vip_major4" v-if="userinfo.vipType == 'perpetual'">
                    永久会员
                </div>
                <div class="vip_major3 vip_major4" v-if="userinfo.vipType == 'experience'">
                    体验会员
                </div>
                <div class="vip_major3 vip_major4" v-if="userinfo.vipType == 'common'">
                    普通会员
                </div>
                <div class="vip_major3 vip_major4" v-if="userinfo.vipType == 'year' || userinfo.vipType == 'month'">
                    {{ userinfo.vipType == 'year' ? '年度会员' :  '月度会员' }}{{ `/将于${userinfo.vipDate}结束` }}
                </div>
            </div>
        </div>

        <div class="vip_major5">
            <vipMajor1></vipMajor1>
            <vipMajor2 @todownload="todownload" @login="toLogin"></vipMajor2>
        </div>
    </div>
</template>

<script>

import data from "@/assets/data";
import api from "@/api/api";
import vipMajor1 from "@/components/vip_major1.vue";
import vipMajor2 from "@/components/vip_major2.vue";
export default {
    components:{
        vipMajor1,vipMajor2
    },
    data() {
        return {
            login:false, //登录转态
            headImg: data.data.headIcon,
            userinfo: {}, // 用户信息
        }
    },
    created() {
        this.getUserinfo()
    },
    methods: {
        toLogin(){
            this.$emit('login')
        },
        todownload(){
            this.$emit('todownload')
        },
        getUserinfo() {
            api.getUserinfo().then((res) => {
                if (res.code == 200) {
                    this.userinfo = res.data
                    this.login = true
                }else{
                    this.login = false
                }
            })
        },
    }

}
</script>

<style>
.vip_major {
    width: 86rem;
    height: 52rem;
    /* background-color: aqua; */
    background: url('../assets/image/vip_major1.png') no-repeat;
    background-size: 100% 100%;
}

.vip_major1 {
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    padding-left: 3rem;
}
.vip_major1 img{
    width: 4.2rem;
    height: 4rem;
    margin-right: 0.8rem;
}
.vip_major3{
    font-size: 1.33rem;
    font-weight: bold;
    color: #745000;
    cursor: default;
    /* margin-bottom: 0.5rem; */
}
.vip_major4{
    font-weight: 400;
}
.vip_major5{
    display: flex;
    height: 41rem;
    margin-top: 4rem;
}
</style>