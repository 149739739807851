<template>
  <div id="userInfo">
    <div class="top">
      <div class="userInfo_head">
        <div class="head_img" v-if="userInfo.vipType == 'common'">
          <img :src="headImg[0]" alt="">
        </div>
        <div class="head_img" v-if="userInfo.vipType == 'month'">
          <img :src="headImg[3]" alt="">
        </div>
        <div class="head_img" v-if="userInfo.vipType == 'year'">
          <img :src="headImg[5]" alt="">
        </div>
        <div class="head_img" v-if="userInfo.vipType == 'experience'">
          <img :src="headImg[5]" alt="">
        </div>
        <div class="head_img" v-if="userInfo.vipType == 'perpetual'">
          <img :src="headImg[7]" alt="">
        </div>
        <div class="head_text">
          <div class="head_name">{{ userInfo.nickname }}</div>
          <div class="head_name1">
            <div class="head_vip" v-if="userInfo.vipType == 'common'">
              会员权益尚未开通
            </div>
            <div class="head_vip" v-if="userInfo.vipType == 'month'">
              月度会员
            </div>
            <div class="head_vip" v-if="userInfo.vipType == 'year'">
              年度会员
            </div>
            <div class="head_vip" v-if="userInfo.vipType == 'experience'">
              体验会员
            </div>
            <div class="head_vip" v-if="userInfo.vipType == 'perpetual'">
              永久会员
            </div>
            <div class="head_vip" v-if="userInfo.vipDate">
              /{{ `会员将于${userInfo.vipDate}结束` }}
            </div>
          </div>
        </div>
      </div>
      <div class="vip_btn" v-if="userInfo.vipType == 'common'">
        开通vip
      </div>
      <div class="vip_btn" @click="openVip" v-if="
        userInfo.vipType == 'month' ||
        userInfo.vipType == 'year' ||
        userInfo.vipType == 'experience'">
        立即续费
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_title">解锁会员权益</div>
      <div class="bottom_box">
        <div class="box_item" v-for="(item, i) in list" :key="i">
          <img :src="item.img" alt="" />
          {{ item.text }}
        </div>
      </div>
      <div class="bottom_btn" @click="logOut">退出登录</div>
    </div>

  </div>
</template>
<script>
import api from "@/api/api.js";
import data from "../assets/data.js";
export default {
  props: {
    userInfo: {
      type: Object,
      default: {},
    },
  },
  created() {
    // console.log(this.userInfo);
  },
  data() {
    return {
      list: data.data.vipEquity,
      headImg: data.data.headIcon,
    };
  },
  methods: {
    openVip() {
      this.$emit("openVip");
    },
    logOut() {
      this.$confirm("是否退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.logout().then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "退出成功！",
                type: "success",
              });
              localStorage.removeItem("token_key");
              location.reload();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    }
  },
};
</script>


<style>
.top {
  width: 100%;
  /* height: 12rem; */
  background: url(../assets/image/userinfo_top.png) no-repeat;
  background-size: 100% 100%;
  padding-bottom: 1rem;
}

.userInfo_head {
  display: flex;
  padding: 1.2rem;
}

.head_img img {
  width: 4.3rem;
  height: 4rem;
}

.head_text {
  margin-left: 0.8rem;
}

.head_name {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 1.2rem;
  color: #333333;
}

.head_name1 {
  display: flex;
}

.head_vip {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 1rem;
  color: #d17300;
  margin-top: 0.5rem;
}

.vip_btn {
  width: 90%;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  background: linear-gradient(-25deg, #fffbf5, #ffdcb6);
  border-radius: 1.5rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 1.3rem;
  color: #c66500;
  margin: 0 auto;
  cursor: pointer;
}

.vip_text {
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
}

.bottom {
  width: 100%;
  padding: 1.5rem 0;
  background: #f9faff;
  border-radius: 0.8rem;
}

.bottom_title {
  width: 100%;
  height: 2.5rem;
  background: url(../assets/image/userinfo_vipbg.png) no-repeat;
  background-position: 0 5px;
  background-size: 100%;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 1rem;
  color: #c86a0c;
}

.bottom_box {
  width: 98%;
  padding: 0 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.box_item {
  width: 6rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 0.9rem;
  color: #000000;
  margin-bottom: 0.8rem;
}

.box_item img {
  width: 4.8rem;
  height: 4.8rem;
  margin-bottom: 0.5rem;
}

.bottom_btn {
  width: 80%;
  height: 2.8rem;
  text-align: center;
  line-height: 2.8rem;
  margin: 0 auto;
  font-size: 1.33rem;
  color: #745000;
  border: 1px solid #745000;
  border-radius: 0.6rem;
  cursor: default;
}
</style>