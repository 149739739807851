<template>
  <div id="talk">
    <top index="1" @login="login"></top>
    <div class="talk_content">
      <talkhistory @hisDetail="hisDetail" @newdialogue="newTalk"></talkhistory>
      <div class="talk_dialogue">
        <!-- 提示问题 -->
        <tips v-if="tipsShow" @putQuestion="askQuestion"></tips>
        <!-- 对话 -->
        <div v-else class="talk_dialogue1" ref="chatContainer">
          <dialogue ref="dialogue" :head="headicon" :max="88" @anwserOver="anwserOver" @downloadW="downloadW"
            @downloadE="downloadE"></dialogue>
        </div>
        <!-- 键盘 -->
        <div class="keyboard_bottom1">
          <keyboard ref="keyboard" @login="login" @putQuestion="askQuestion" :nextQuestion="nextanwser"
            @stopTalk="stopTalk">
          </keyboard>
          <div class="bottom_number">
            <a href="https://beian.miit.gov.cn"> 鄂ICP备2023031487号 </a>
            <div>Copyright © 2023 - 2025 hbsry.com 版权所有</div>
          </div>
        </div>
      </div>
    </div>
    <login ref="login"></login>
    <gzhpopup></gzhpopup>
  </div>
</template>

<script>
import top from '@/components/top.vue'
import talkhistory from '@/components/talk_history.vue'
import dialogue from '@/components/dialogue.vue';
import tips from '@/components/tips.vue'
import keyboard from '@/components/keyboard.vue'
import login from '@/components/login.vue';
import api from "@/api/api";
import gzhpopup from '@/components/gzhpopup.vue'
import * as XLSX from 'xlsx';
import { exportWord } from 'mhtml-to-word';
import Vue from 'vue';
import VueMeta from 'vue-meta';
Vue.use(VueMeta);
export default {
  metaInfo() {
    return {
      title: 'AI对话',
      meta: [
        // {name:'description',content:'智能写作'},
        // {name:'keywords',content:'AI,智能,写作'},
      ]
    }
  },
  components: {
    top, talkhistory, dialogue, tips, keyboard, login, gzhpopup
  },
  data() {
    return {
      tipsShow: true, //提示内容显示
      talkList: [], //ai对话内容
      sessionId: 0, //会话id
      headicon: require('@/assets/image/Aitalk.png'), //头像
      nextanwser: false, //下一个问题
      stopTalkid: 0, // 停止对话id
      intervalId: null, // 定时器ID
      tmp: null,
      ifTable: false,
      // tyTable: false,  //流式是否是表格
      htmlTable: false, //流式是否有表格
      ifTableHead: false, // 流式是否是表头
      nextTable: false,// 流式下一个表格
      tyTable: false, // 是否有表格
      htmlText: '',//流式内容暂存
      htmlText1: "",//流式内容暂存1
      talkEnd: false, //流式结束

    }
  },
  created() {
    this.newTalk();
  },
  methods: {
    // 登录
    login() {
      this.$refs.login.show()
    },
    // 创建新对话
    newTalk(i) {
      if (i == "new") {
        this.tipsShow = true
        this.talkList = [];
        if (!this.tipsShow) {
          setTimeout(() => {
            this.$refs.dialogue.getQuestion(this.talkList);
          }, 10)
        }
      }
      api.createSession().then((res) => {
        if (res.code == 200) {
          if (i == "new") {
            this.$message({
              message: "新对话已创建",
              type: "success",
            });
          }
          this.sessionId = res.data;
        }
      });
    },
    // 查询对话详情
    hisDetail(data) {
      this.tipsShow = false
      this.pageNum = 1;
      this.talkList = [];
      this.sessionId = data.id
      this.headicon = data.icon
      api.contentSession({
        sessionId: data.id,
        pageNum: this.pageNum,
        pageSize: 50,
      }).then((res) => {
        if (res.code == 200) {
          for (let item of res.data) {
            item.downloadShow = false
            item.anwser = this.getTable(item);

            // console.log(item)
          }
          this.talkList = this.talkList.concat(res.data.reverse());
        }
        setTimeout(() => {
          this.$refs.dialogue.getQuestion(this.talkList);
          this.scrollToBottom();
        }, 10)
      })

    },
    getTable(item) {
      // console.log('item--',item)
      let html = ''
      let typeLable = item.anwser.indexOf("|");
      let result = [];
      this.ifTable = false
      // 若存在 | 表格 若不存在 | 非表格
      if (typeLable !== -1) {
        item.downloadShow = true
        let parts = item.anwser.split('\n');
        // console.log(parts);
        for (let i = 0; i < parts.length; i++) {
          if (parts[i].indexOf("|") == -1) {
            result.push(parts[i]);
          } else if (parts[i].indexOf("|") !== -1) {
            if (parts[i].trim().startsWith("|")) {
              let line = parts[i].split("|");
              result.push(line);
            }
          }
        }
        // console.log(result);
        for (let i = 0; i < result.length; i++) {
          if (typeof result[i] === "string") {
            if (this.ifTable) {
              html += '</tbody></table></div>'
              this.ifTable = false
            } else {
              html += result[i] == '' ? '</br>' : result[i] + '</br>'
            }
          } else if (typeof result[i] == 'object') {
            if (i == result.length - 1) {
              html += this.getStreamHtml(result[result.length - 1], 1) + '</tbody></table></div>'
            } else {
              if (!this.ifTable) {
                html += this.getStreamHtml(result[i], 0)
                this.ifTable = true
              } else {
                html += this.getStreamHtml(result[i], 1)
              }
            }
          }
        }
        return html;
      } else {
        return item.anwser.replace(/\n/g, "<br/>");
      }
    },

    // 提问
    askQuestion(item) {
      if (this.sessionId == 0) {
        this.$refs.login.show()
        return
      }
      this.talkEnd = false
      this.nextTable = true
      this.tyTable = false
      this.ifTableHead = false
      this.htmlText = ''
      this.nextanwser = true;
      this.htmlTable = false
      this.tipsShow = false
      let arry = {
        question: item,
        sessionId: this.sessionId,
        anwser: "",
        downloadShow: false,
      };
      this.talkList.push(arry);
      this.getAnwser(arry);
      setTimeout(() => {
        this.$refs.dialogue.getQuestion(this.talkList);
      }, 10)
      this.intervalId = setInterval(() => {
        // console.log('111',this.intervalId)
        this.scrollToBottom();
      }, 500)

    },
    // 流式请求获取回答
    async getAnwser(data) {
      // // fetch请求
      const token = localStorage.getItem("token_key");
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      };
      if (token == null) {
        headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
      }
      data.platform = 2
      const options = {
        method: "POST", // 请求方法为POST
        body: new URLSearchParams(data).toString(), // 将可读流作为请求体
        // 其他选项，如headers等（如果需要）
        headers: headers,
        // 指定响应类型为stream
        responseType: 'stream',
      };
      // 使用fetch API发起请求，指定响应类型为response以获取ReadableStream对象
      const response = await fetch(
        process.env.VUE_APP_BASE_API + "assistant/app/session/question/stream",
        options
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // 获取可读流读取器对象 循环
      const reader = response.body.getReader();
      this.$refs.keyboard.degree()
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const decoder = new TextDecoder();
        const txt = decoder.decode(value);
        const t = this.getStr(txt)

        if (t == "登录状态已过期") {
          this.$refs.login.show()
          return
        }
        if (!this.htmlTable) {
          this.talkList[this.talkList.length - 1].anwser =
            this.talkList[this.talkList.length - 1].anwser + this.createTable(t)
        } else {
          if (!this.talkEnd) {
            this.talkList[this.talkList.length - 1].anwser =
              this.talkList[this.talkList.length - 1].anwser + this.createTable(t)
          } else if (this.talkEnd) {
            if (this.htmlText1 !== '') {
              let createdTable = ''
              let startIndex = this.htmlText1.indexOf("|")
              if (startIndex !== -1) {
                let parts = this.htmlText1.split("|")
                createdTable = this.getStreamHtml(parts, 1) + '</tbody></table></div>'
              } else {
                createdTable = this.htmlText1.replace(/\n/g, "<br/>")
              }
              this.talkList[this.talkList.length - 1].anwser =
                this.talkList[this.talkList.length - 1].anwser + createdTable
            } else {
              this.talkList[this.talkList.length - 1].anwser =
                this.talkList[this.talkList.length - 1].anwser + this.createTable(t)
            }
          }
        }

        setTimeout(() => {
          this.$refs.dialogue.getQuestion(this.talkList);
        }, 10)
      }
      this.$refs.dialogue.over()
    },
    // 返回值拼接
    getStr(str) {
      // console.log(str)
      if (this.tmp) {
        str = this.tmp + str
      }
      let arr = str.split("\n\n");
      let res = "";
      for (let s of arr) {
        if (!s || s == "") {
          continue;
        }
        try {
          let obj = JSON.parse(s);
          const code = obj.code;
          if ("401" == code) {
            return "";
          } else if ("700" == code) {
            this.talkEnd = true
            break;
          } else if ("200" != code) {
            return obj.msg;
          } else {
            const data = obj.data;
            res += data;
            this.stopTalkid = obj.id
          }
          this.tmp = null;
        } catch (err) {
          console.log(err);
          if (this.tmp) {
            this.tmp = null
          } else {
            this.tmp = str
          }
        }
      }
      return res;
    },
    createTable(i) {
      // console.log(i)
      let t = ''
      if (this.nextTable) {
        t = this.htmlText + i
        this.nextTable = false
      } else {
        t = i
      }
      let createdTable = ''
      let startIndex = t.indexOf("|") //生成表格
      let front = ''
      if (startIndex !== -1 || this.tyTable) {
        this.talkList[this.talkList.length - 1].downloadShow = true
        this.htmlTable = true
        if (!this.tyTable) {
          // 截取|前面
          front = t.slice(0, startIndex)
          createdTable = front.replace(/\n/g, "<br/>")
          this.htmlText = t.replace(front, '')
          this.tyTable = true
        } else {
          this.htmlText1 = this.htmlText += t
          if (this.htmlText.charAt(0) == '|') {
            let headIndex = this.htmlText.indexOf("|\n")
            let a = ''
            if (headIndex !== -1) {
              a = this.htmlText.slice(0, headIndex + 1)
              let parts = a.split("|");
              if (!this.ifTableHead) {
                createdTable = this.getStreamHtml(parts, 0)
                this.htmlText1 = ''
                this.ifTableHead = true
              } else {
                createdTable = this.getStreamHtml(parts, 1)
                this.htmlText1 = ''
              }
              this.htmlText1 = this.htmlText = this.htmlText.replace(a + '\n', '')
            }
          } else if (this.htmlText.charAt(0) !== '|') {
            createdTable = '</tbody></table></div>'
            this.nextTable = true
            this.tyTable = false
            this.ifTableHead = false
          }
        }
      } else {
        createdTable = t.replace(/\n/g, "<br/>")
        this.htmlText1 = ''
      }
      return createdTable
    },
    getStreamHtml(tableData, i) {
      tableData.shift()
      tableData.pop()
      if (i == 0) {
        this.lableHead = false
        let htmlString = '<div class="table_style"><table border="1">';
        // 生成表头
        htmlString += '<thead><tr>';
        for (let header of tableData) {
          htmlString += `<th>${header}</th>`;
        }
        htmlString += '</tr></thead><tbody>';
        return htmlString
      } else if (i == 1) {
        let htmlString = '<tr>';
        for (let cell of tableData) {
          htmlString += `<td>${cell}</td>`;
        }
        htmlString += '</tr>';
        return htmlString
      }

    },

    scrollToBottom() {
      // 使用Vue的$nextTick确保DOM更新完成后再进行滚动操作
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部
      });
    },
    anwserOver() {
      // 问题回答完毕
      this.nextanwser = false;
      // console.log('222',this.intervalId)
      clearInterval(this.intervalId)
    },
    // 下载
    downloadW(item) {
      if (item.i == this.talkList.length - 1) {
        if (this.nextanwser) {
          this.$message({
            message: '请等待问题回答完毕，或者手动停止！',
            type: 'warning'
          });
          return
        }
        this.downloadWord(item.html)
      } else {
        this.downloadWord(item.html)
      }
    },
    downloadWord(html) {
      let htmlStr = `
                      <!DOCTYPE html>
                      <html lang="en">
                      <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Document</title>
                      </head>
                      <body>
                        ${html}
                      </body>
                      </html>
                    `;
      exportWord({
        mhtml: htmlStr,
        data: { title: "exportword" },
        filename: new Date().getTime(),
        style: ""
      })
    },
    downloadE(item) {
      if (item.i == this.talkList.length - 1) {
        if (this.nextanwser) {
          this.$message({
            message: '请等待问题回答完毕，或者手动停止！',
            type: 'warning'
          });
          return
        }
        this.downloadExcel(item.html)
      } else {
        this.downloadExcel(item.html)
      }

    },
    downloadExcel(item){
      const parser = new DOMParser();
      const doc = parser.parseFromString(item, 'text/html');
      const tables = doc.querySelectorAll('table');
      const workbook = XLSX.utils.book_new();
      
      // 遍历每个表格并添加到工作簿的不同工作表中
      tables.forEach((table, index) => {
        const worksheet = XLSX.utils.table_to_sheet(table);
        const sheetName = `Sheet${index + 1}`;
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      });
      
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      const fileName = new Date().getTime() + '.xlsx';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },
    //停止对话
    stopTalk() {
      api.stopquestion(this.stopTalkid).then((res) => {
        if (res.code == 200) {
          clearInterval(this.intervalId)
        }
      })
    }
  }
}
</script>

<style>
#talk {
  width: 128rem;
  height: 100vh;
  background: url('../assets/image/bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 5rem;
}

.talk_content {
  width: 128rem;
  height: 90vh;
  /* background-color: aqua; */
  padding: 0;
  margin-top: 1vh;
  display: flex;

}

.talk_dialogue {
  width: 114rem;
  height: 90vh;
  position: relative;
}

.talk_dialogue1 {
  width: 70rem;
  height: 70vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE和Edge浏览器下隐藏滚动条 */
  scrollbar-width: none;
  /* Firefox下隐藏滚动条 */
  margin: 0 auto;
}

.keyboard_bottom1 {
  width: 70rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.bottom_number {
  width: 70rem;
  text-align: center;
  font-weight: 600;
  color: #000000;
  font-size: 0.8rem;
  margin-top: 1vh;
}

.table_style {
  width: 100%;
  overflow-x: auto;
}

table {
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #d0d7de;
}

th {
  background-color: #c4c4c4;
}

th,
td {
  white-space: nowrap;
  padding: 10px;
  text-align: center;
}
</style>