<template>
  <div id="adviser">
    <top index="2" @login="login"></top>
    <div class="content">
      <!-- 左边导航栏 -->
      <homeLeft @openmyCreate="openmyCreate"></homeLeft>
      <!-- 右边主体列表 -->
      <div v-if="adviser" class="adviser_right">
        <div class="adviser_title">AI顾问</div>
        <div class="box">
          <div class="box1">
            <div class="adviser_box" :style="{
              backgroundImage: `url(${item.titleColor == 0 ? adviseBg[0]
                : item.titleColor == 1 ? adviseBg[1] : item.titleColor == 2 ? adviseBg[2] : adviseBg[3]}`
            }"
              v-for="(item, i) in list" @click="adviserTalk(i)">
              <div class="adviser_box1">
                <img :src="item.icon" alt="">
                <div class="adviser_box2">{{ item.name }}</div>
              </div>
              <div class="adviser_box3">
                <div class="adviser_box4">
                  {{ item.subTitle }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 对话 -->
      <div v-else class="adviser_right">
        <div class="adviser_title" @click="adviser = true">
          <img src="../assets/image/adviser_icon.png" alt="">
          <div>{{ list[adviserIndex].name }}</div>
        </div>
        <div class="adviser_dialogue" ref="chatContainer">
          <dialogue ref="dialogue" :cueword="list[adviserIndex].salutation" :head="list[adviserIndex].icon"
            @anwserOver="anwserOver" @downloadW="downloadW" @downloadE="downloadE"></dialogue>
        </div>
        <div class="keyboard_bottom">
          <keyboard ref="keyboard" :tips="list[adviserIndex].tips" @putQuestion="askQuestion" @login="login"
            :nextQuestion="nextanwser" @stopTalk="stopTalk"></keyboard>
        </div>
      </div>
    </div>

    <div class="bottom_number">
      <a href="https://beian.miit.gov.cn"> 鄂ICP备2023031487号 </a>
      <div>Copyright © 2023 - 2025 hbsry.com 版权所有</div>
    </div>

    <login ref="login"></login>
    <myCreate ref="myCreate"></myCreate>
    <gzhpopup></gzhpopup>
  </div>
</template>

<script>
import top from '@/components/top.vue'
import homeLeft from '@/components/home_left.vue';
import dialogue from '@/components/dialogue.vue'
import keyboard from '@/components/keyboard.vue'
import login from '@/components/login.vue';
import myCreate from '@/components/my_create.vue'
import api from "@/api/api";
import data from '@/assets/data';
import gzhpopup from '@/components/gzhpopup.vue'
import Vue from 'vue';
import VueMeta from 'vue-meta';
import * as XLSX from 'xlsx';
Vue.use(VueMeta);
export default {
  metaInfo(){
    return{
      title:'AI顾问',
      meta:[
        // {name:'description',content:'智能写作'},
        // {name:'keywords',content:'AI,智能,写作'},
      ]
    }
  },
  components: {
    top, homeLeft, dialogue, keyboard, login, myCreate, gzhpopup
  },
  created() {
    this.getAdviser()
    this.newTalk()
  },
  data() {
    return {
      adviser: true, // 是否显示对话
      adviserIndex: 0, //选中下标
      list: [], // 顾问列表
      sessionId: 0, //会话id
      talkList: [], //对话列表
      nextanwser: false, //下一个问题
      stopTalkid: 0, // 停止对话id
      intervalId: null, // 定时器ID
      tmp: null,
      adviseBg: data.data.adviseBg,
      anwserText: "", //返回回答
      htmlTable: false, //流式是否有表格
      ifTableHead: false, // 流式是否是表头
      nextTable: false,// 流式下一个表格
      tyTable: false, // 是否有表格
      htmlText: '',//流式内容暂存
      htmlText1: "",//流式内容暂存1
      talkEnd: false, //流式结束
    }
  },
  methods: {
    getAdviser() {
      api.getAdviser().then((res) => {
        // console.log(res)
        if (res.code == 200) {
          this.list = res.data
        }
      });
    },
    // 登录
    login() {
      this.$refs.login.show()
    },
    // 创建新对话
    newTalk(i) {
      api.createSession().then((res) => {
        if (res.code == 200) {
          this.sessionId = res.data;
        }
      });
    },
    // 顾问对话
    adviserTalk(i) {
      this.adviser = false
      this.adviserIndex = i
    },
    // 我的创作
    openmyCreate() {
      this.$refs.myCreate.show()
    },

    // 提问
    askQuestion(item) {
      this.nextanwser = true;

      this.talkEnd = false
      this.nextTable = true
      this.tyTable = false
      this.ifTableHead = false
      this.htmlText = ''
      this.nextanwser = true;
      this.htmlTable = false
      this.tipsShow = false

      let arry = {
        question: item,
        sessionId: this.sessionId,
        anwser: "",
        adviser: this.list[this.adviserIndex].type,
        downloadShow: false,
      };
      this.talkList.push(arry);
      this.getAnwser(arry);
      setTimeout(() => {
        this.$refs.dialogue.getQuestion(this.talkList);
      }, 10)
      this.intervalId = setInterval(() => {
        // console.log('111',this.intervalId)
        this.scrollToBottom();
      }, 500)

    },
    // 流式请求获取回答
    async getAnwser(data) {
      // // fetch请求
      const token = localStorage.getItem("token_key");
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      };
      if (token == null) {
        headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
      }
      data.platform = 2
      const options = {
        method: "POST", // 请求方法为POST
        body: new URLSearchParams(data).toString(), // 将可读流作为请求体
        // 其他选项，如headers等（如果需要）
        headers: headers,
        // 指定响应类型为stream
        responseType: 'stream',
      };
      // 使用fetch API发起请求，指定响应类型为response以获取ReadableStream对象
      const response = await fetch(
        process.env.VUE_APP_BASE_API + "assistant/app/session/question/stream",
        options
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // 获取可读流读取器对象 循环
      const reader = response.body.getReader();
      this.$refs.keyboard.degree()
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const decoder = new TextDecoder();
        const txt = decoder.decode(value);
        const t = this.getStr(txt)
        if (t == "登录状态已过期") {
          this.$refs.login.show()
          return
        }
        if (!this.htmlTable) {
          this.talkList[this.talkList.length - 1].anwser =
            this.talkList[this.talkList.length - 1].anwser + this.createTable(t)
        } else {
          if (!this.talkEnd) {
            this.talkList[this.talkList.length - 1].anwser =
              this.talkList[this.talkList.length - 1].anwser + this.createTable(t)
          } else if (this.talkEnd) {
            if (this.htmlText1 !== '') {
              let createdTable = ''
              let startIndex = this.htmlText1.indexOf("|")
              if (startIndex !== -1) {
                let parts = this.htmlText1.split("|")
                createdTable = this.getStreamHtml(parts, 1) + '</tbody></table></div>'
              } else {
                createdTable = this.htmlText1.replace(/\n/g, "<br/>")
              }
              this.talkList[this.talkList.length - 1].anwser =
                this.talkList[this.talkList.length - 1].anwser + createdTable
            } else {
              this.talkList[this.talkList.length - 1].anwser =
                this.talkList[this.talkList.length - 1].anwser + this.createTable(t)
            }
          }
        }
        setTimeout(() => {
          this.$refs.dialogue.getQuestion(this.talkList);
        }, 10)
      }
      this.$refs.dialogue.over()
    },
    // 返回值拼接
    getStr(str) {
      if (this.tmp) {
        str = this.tmp + str
      }
      let arr = str.split("\n\n");
      let res = "";
      for (let s of arr) {
        if (!s || s == "") {
          continue;
        }
        try {
          let obj = JSON.parse(s);
          const code = obj.code;
          if ("401" == code) {
            return "";
          } else if ("700" == code) {
            this.talkEnd = true
            break;
          } else if ("200" != code) {
            return obj.msg;
          } else {
            const data = obj.data;
            res += data;
            this.stopTalkid = obj.id
          }
          this.tmp = null;
        } catch (err) {
          console.log(err);
          if (this.tmp) {
            this.tmp = null
          } else {
            this.tmp = str
          }
        }
      }
      return res;
    },
    createTable(i) {
      // console.log(i)
      let t = ''
      if (this.nextTable) {
        t = this.htmlText + i
        this.nextTable = false
      } else {
        t = i
      }
      let createdTable = ''
      let startIndex = t.indexOf("|") //生成表格
      let front = ''
      if (startIndex !== -1 || this.tyTable) {
        this.htmlTable = true
        this.talkList[this.talkList.length - 1].downloadShow = true
        if (!this.tyTable) {
          // 截取|前面
          front = t.slice(0, startIndex)
          createdTable = front.replace(/\n/g, "<br/>")
          this.htmlText = t.replace(front, '')
          this.tyTable = true
        } else {
          this.htmlText1 = this.htmlText += t
          if (this.htmlText.charAt(0) == '|') {
            let headIndex = this.htmlText.indexOf("|\n")
            let a = ''
            if (headIndex !== -1) {
              a = this.htmlText.slice(0, headIndex + 1)
              let parts = a.split("|");
              if (!this.ifTableHead) {
                createdTable = this.getStreamHtml(parts, 0)
                this.htmlText1 = ''
                this.ifTableHead = true
              } else {
                createdTable = this.getStreamHtml(parts, 1)
                this.htmlText1 = ''
              }
              this.htmlText1 = this.htmlText = this.htmlText.replace(a + '\n', '')
            }
          } else if (this.htmlText.charAt(0) !== '|') {
            createdTable = '</tbody></table></div>'
            this.nextTable = true
            this.tyTable = false
            this.ifTableHead = false
          }
        }
      } else {
        createdTable = t.replace(/\n/g, "<br/>")
        this.htmlText1 = ''
      }
      return createdTable
    },
    getStreamHtml(tableData, i) {
      tableData.shift()
      tableData.pop()
      if (i == 0) {
        this.lableHead = false
        let htmlString = '<div class="table_style"><table border="1">';
        // 生成表头
        htmlString += '<thead><tr>';
        for (let header of tableData) {
          htmlString += `<th>${header}</th>`;
        }
        htmlString += '</tr></thead><tbody>';
        return htmlString
      } else if (i == 1) {
        let htmlString = '<tr>';
        for (let cell of tableData) {
          htmlString += `<td>${cell}</td>`;
        }
        htmlString += '</tr>';
        // console.log('111',htmlString)
        return htmlString
      }
    },
    scrollToBottom() {
      // 使用Vue的$nextTick确保DOM更新完成后再进行滚动操作
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部
      });
    },
    anwserOver() {
      // 问题回答完毕
      this.nextanwser = false;
      // console.log('222',this.intervalId)
      clearInterval(this.intervalId)
    },
    // 下载
    downloadW(item) {
      if (item.i == this.talkList.length - 1) {
        if (this.nextanwser) {
          this.$message({
            message: '请等待问题回答完毕，或者手动停止！',
            type: 'warning'
          });
          return
        }
        this.downloadWord(item.html)
      } else {
        this.downloadWord(item.html)
      }
    },
    downloadWord(html) {
      let htmlStr = `
                      <!DOCTYPE html>
                      <html lang="en">
                      <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Document</title>
                      </head>
                      <body>
                        ${html}
                      </body>
                      </html>
                    `;
      exportWord({
        mhtml: htmlStr,
        data: { title: "exportword" },
        filename: "exportTest",
        style: ""
      })
    },
    downloadE(item) {
      if (item.i == this.talkList.length - 1) {
        if (this.nextanwser) {
          this.$message({
            message: '请等待问题回答完毕，或者手动停止！',
            type: 'warning'
          });
          return
        }
        this.downloadExcel(item.html)
      } else {
        this.downloadExcel(item.html)
      }

    },
    downloadExcel(item){
      const parser = new DOMParser();
      const doc = parser.parseFromString(item, 'text/html');
      const tables = doc.querySelectorAll('table');
      const workbook = XLSX.utils.book_new();
      
      // 遍历每个表格并添加到工作簿的不同工作表中
      tables.forEach((table, index) => {
        const worksheet = XLSX.utils.table_to_sheet(table);
        const sheetName = `Sheet${index + 1}`;
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      });
      
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      const fileName = new Date().getTime() + '.xlsx';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },
    //停止对话
    stopTalk() {
      api.stopquestion(this.stopTalkid).then((res) => {
        if (res.code == 200) {
          clearInterval(this.intervalId)
        }
      })
    }
  }
}
</script>

<style>
#adviser {
  width: 128rem;
  height: 100vh;
  background: url('../assets/image/bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 5rem;
}

.content {
  width: 128rem;
  height: 90vh;
  /* background-color: aqua; */
  margin-top: 1vh;
}

.adviser_right {
  width: 118rem;
  height: 87vh;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px 20px 20px 20px;
  padding: 2rem 4rem;
  position: relative;
}

.adviser_title {
  color: #000000;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
  display: flex;
  cursor: pointer;
}

.adviser_title img {
  width: 2.1rem;
  height: 2.1rem;
  margin-right: 1rem;
}

.box {
  width: 110rem;
  height: 76vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE和Edge浏览器下隐藏滚动条 */
  scrollbar-width: none;
  /* Firefox下隐藏滚动条 */
}

.box1 {
  display: flex;
  flex-wrap: wrap;
}

.adviser_box {
  width: 26rem;
  height: 17rem;
  /* background: url('../assets/image/adviser_bg.png') no-repeat; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 1.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.adviser_box1 {
  width: 26rem;
  display: flex;
  padding: 0 2rem;
  justify-content: space-between;
}

.adviser_box1 img {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: aqua;
}

.adviser_box2 {
  width: 18rem;
  height: 3.2rem;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 2.5rem;
  text-align: center;
}

.adviser_box3 {
  width: 22rem;
  height: 8.5rem;
  margin-left: 2rem;
  margin-top: 1.5rem;
  padding: 0.5rem 2rem;
  overflow: hidden;

}

.adviser_box4 {
  font-size: 1rem;
  color: #333333;
  font-weight: 600;
  /* background-color: aqua; */
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.adviser_dialogue {
  height: 68vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE和Edge浏览器下隐藏滚动条 */
  scrollbar-width: none;
  /* Firefox下隐藏滚动条 */
  margin-bottom: 2vh;
  /* background-color: aqua; */
}

.keyboard_bottom {
  width: 110rem;
  /* background-color: aqua; */
  position: absolute;
  bottom: 1vh;
  left: 4rem;
}

#adviser .bottom_number {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 70rem;
  text-align: center;
  font-weight: 600;
  color: #000000;
  font-size: 0.8rem;
}
</style>