<template>
    <div class="tips">
        <div class="tips1">你好！我是AI对话</div>
        <div class="tips2">请问有什么我可以帮助你的吗？无论是关于生活、工作、学习还 是其他方面的问题，我都会尽力回答你。</div>
        <div class="tips3">你可以尝试这样这样问我：</div>
        <div class="tips4">
            <div class="tips5" v-for="(item, i) in questionlist" :key="i" @click="select(item)">
                {{ item }}
                <img src="../assets/image/tips_icon.png" alt="">
            </div>
        </div>
        <div class="tips6">
            <img src="../assets/image/talk_tips.png" alt="">
        </div>
    </div>
</template>

<script>
import api from "@/api/api";
export default {
    created() {
        this.getPrompt()
    },
    data() {
        return {
            tipsIndex: -1,//选中历史下标
            questionlist: []
        }
    },
    methods: {
        // 选择问题
        select(i) {
            this.$emit("putQuestion", i);
        },
        // 获取提示问题
        getPrompt() {
            api.getPrompt('znwd').then((res) => {
                // console.log(res)
                if (res.code == 200) {
                    this.questionlist = res.data.question
                }
            });
        }
    }
}
</script>


<style>
.tips {
    width: 70rem;
    margin: 0 auto;
    background-color: #FFFFFF;
    border-radius: 1.5rem;
    padding: 2rem 4rem;
    padding-bottom: 1rem;
    position: relative;
}

.tips1 {
    font-size: 1.8rem;
    color: #111111;
    font-weight: 700;
}

.tips2 {
    font-size: 1.1rem;
    color: #111111;
    width: 42rem;
    line-height: 2;
    margin: 1rem 0;
    font-weight: 600;
}

.tips3 {
    color: #111111;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.tips4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tips5 {
    width: 30.5rem;
    margin-bottom: 1rem;
    color: #111111;
    font-size: 1.1rem;
    background-color: #DCEBFF;
    padding: 1rem 1.6rem;
    padding-right: 4rem;
    border-radius: 3rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    font-weight: 600;
    cursor: pointer;
}

.tips5:hover {
    background-color: #ADCFFF;
}

.tips5 img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.2rem;
    width: 2.3rem;
}
.tips6{
    position: absolute;
    top: -1.5rem;
    right: 3rem;
}
.tips6 img{
    width: 15.2rem;
}
</style>