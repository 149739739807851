<template>
    <div class="vip_major6">
        <div class="vip_major7">
            <div class="vip_major8">
                <img src="../assets/image/vip_major2.png" alt="">
            </div>
            <div class="vip_major9">
                <div class="vip_major10" v-for="(item, i) in vipEquity" :key="i">
                    <img :src="item.img" alt="">
                    <div class="vip_major10_1">{{ item.text }}</div>
                </div>
            </div>
        </div>

        <div class="vip_major11">
            <div class="vip_major12">
                会员说明
            </div>
            <div class="vip_major13">
                <div class="vip_major14" v-for="(item, i) in vipNotice" :key="i">
                    {{ item }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import data from '@/assets/data';

export default {
    data() {
        return {
            vipEquity: data.data.vipEquity,
            vipNotice: data.data.vipNotice
        }
    },
}
</script>
<style>
.vip_major6 {
    width: 28rem;
    padding-top: 1rem;
    padding-left: 0.8rem;
    /* background-color: aqua; */
}

.vip_major8 {
    width: 20rem;
    margin: 0 auto;
    margin-bottom: 2rem;
}
.vip_major8 img{
    width: 18rem;
    margin-left: 1rem;
}

.vip_major9 {
    width: 27rem;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin: 0 auto;
}

.vip_major10 {
    width: 6.5rem;
    display: flex;
    align-items: center;
    flex-flow: column;
    color: #FFFFFF;
    margin-bottom: 0.8rem;

}

.vip_major10_1 {
    font-size: 1.1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.vip_major10 img {
    width: 2.66rem;
    height: 2.66rem;
    margin-bottom: 0.5rem;
}

.vip_major12 {
    width: 27rem;
    font-size: 1.33rem;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 2.5rem;
}

.vip_major13 {
    width: 27rem;
    margin: 0 auto;
    padding: 0.8rem;
    border: 1px solid #EEB767;
    border-radius: 0.8rem;
    color: #FFFFFF;
    font-size: 1.1rem;
}
.vip_major14{
    margin-bottom: 0.3rem;
}
</style>