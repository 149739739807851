<template>
    <div id="home_lable">
        <div class="home_lable1">
            <div v-for="(item, i) in lableList" :key="i" @click="chang(i)"
                :class="lableIndex == i ? 'home_lable2 home_lable3' : 'home_lable2'">
                {{ item }}
            </div>
        </div>

        <div class="home_lable4">
            <div class="home_lable5">
                <div class="home_lable6" v-for="(item, i) in list" @click="toCreate(item)">
                    <img :src="item.pcIcon ? item.pcIcon : item.icon" alt="">
                    <div class="home_lable7">
                        <div class="home_lable8">{{ item.name }}</div>
                        <div class="home_lable9">{{ item.description }}</div>
                    </div>
                    <div class="home_lable10" v-if="item.vip">会员</div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import api from "@/api/api";
import data from "@/assets/data"
export default {
    data() {
        return {
            lableList: ['热门创作', '会员专区', '工作办公', '教育', '媒体写作', '学习', '娱乐'], //lable列表
            lableIndex: 0, //lable下标
            list: [],
            remArry: [{ type: "aigw" }, { type: "pcdnb" }], //pc端没有的
        }
    },
    created() {
        this.getHome(0)
    },
    methods: {
        chang(i) {
            this.list = []
            this.lableIndex = i
            if (i == 0 || i == 1) {
                this.getHome(i)
            } else if (i == 2) {
                this.getWork({ module: 'work' })
            } else if (i == 3) {
                this.getWork({ module: 'education' })
            } else if (i == 4) {
                this.getWork({ module: 'writing' })
            } else if (i == 5) {
                this.getWork({ module: 'study' })
            } else if (i == 6) {
                this.getWork({ module: 'recreation' })
            }
        },
        // 前往创作页面
        toCreate(item) {
            this.$router.push({ path: '/create', query: { title: this.lableList[this.lableIndex] ,name: item.name,type:item.type } });
        },
        // 获取首页热门，会员
        getHome(i) {
            api.getHome().then((res) => {
                // console.log(res)
                if (res.code == 200) {
                    if (i == 0) {
                        let list = data.data.homeHot
                        this.list = list.concat(this.removeDuplicates(this.remArry, res.data.hots));
                    } else if (i == 1) {
                        this.list = this.list.concat(this.removeDuplicates(this.remArry, res.data.vip));
                    }
                }
            })
        },
        getWork(params) {
            api.getWork(params).then((res) => {
                // console.log(res)
                if (res.code == 200) {
                    this.replaceIcon(res.data)
                }
            });
        },
        // 删除数组中元素
        removeDuplicates(arr1, arr2) {
            const type = arr1.map((item) => item.type);
            return arr2.filter((item) => !type.includes(item.type));
        },
        // 替换图标
        replaceIcon(data) {
            // data.map((item) => {
            //     if (item.name == '公文写作') {
            //         item.icon = require(`@/assets/icon/${item.type}.png`)
            //     }
            // })
            this.list = data
        }
    }
}
</script>
<style>
#home_lable {
    height: 63vh;
    margin-top: 2vh;
    /* background-color: orange; */
}

.home_lable1 {
    width: 118rem;
    display: flex;
}

.home_lable2 {
    width: 8rem;
    height: 3.2rem;
    line-height: 3.2rem;
    text-align: center;
    color: #000000;
    font-size: 1.3rem;
    font-weight: 600;
    background-color: #ffffff;
    border-radius: 0.5rem;
    border: 1px solid #ffffff;
    margin-right: 1rem;
    cursor: pointer;
}

.home_lable3 {
    color: #409EFF;
    border: 1px solid #409EFF;
}

.home_lable4 {
    width: 118rem;
    height: 52vh;
    margin-top: 2vh;
    /* background-color: orange; */
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
}

.home_lable4::-webkit-scrollbar {
    display: none;
    /* 在Webkit内核的浏览器中隐藏滚动条 */
}

.home_lable5 {
    display: flex;
    flex-wrap: wrap;
    cursor: default;
}

.home_lable6 {
    width: 22rem;
    height: 10rem;
    background-color: #ffffff;
    border-radius: 1rem;
    margin-right: 1.3rem;
    margin-bottom: 1.3rem;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.home_lable6 img {
    width: 4.8rem;
    height: 4.13rem;
}

.home_lable7 {
    width: 11.5rem;
}

.home_lable8 {
    color: #333333;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.home_lable9 {
    color: #666666;
    font-size: 1rem;
}

.home_lable10 {
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(180deg, #FDEEBA 0%, #FED48E 100%);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0px 1rem 0 1rem;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: #5F3C07;
}
</style>