<template>
    <div>
        <div class="create_bottom" v-if="composition">
            <div class="create_bottom1" 
            v-for="(item, i) in resultBtn" :key="i" @click="bottomBTn(i)">
                <img :src="item.icon" alt="">
                {{ item.name }}
            </div>
            <div class="create_bottom1" @click="bottomBTn(3)">
                <img src="../assets/image/create_icon10.png" alt="">
                终止
            </div>
        </div>
        <div class="create_bottom" v-else>
            <div class="create_bottom1" @click="polishing">
                <img src="../assets/image/create_icon9.png" alt="">
                润色
            </div>
        </div>
    </div>

</template>

<script>
import data from '@/assets/data';
export default {
    props: {
        composition: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            resultBtn: data.data.resultBtn
        }
    },
    methods: {
        bottomBTn(i) {
            this.$emit('bottomBTn', i)
        },
        polishing(){
            this.$emit('polishing')
        }
    }
}
</script>

<style>
.create_bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 8vh;
    background-color: #FFFFFF;
    border-radius: 0 0 0.5rem 0;
    display: flex;
    padding: 0 30rem;
    box-shadow: 0px -2px 6px 0 #adadad;
}

.create_bottom1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    color: #000000;
    font-size: 1rem;
    margin: 0 1.2rem;
    cursor: default;
}

.create_bottom1 img {
    width: 2.5rem;
    margin-bottom: 0.2rem;
}
</style>