<template>
    <div class="vip_price">
        <!-- 支付按钮 -->
        <div class="pay_btn">
            <div :class="payIndex == 0 ? 'pay_btn1 pay_btn3' : 'pay_btn1'" @click="chang(0)">
                <img :src="payIndex == 0 ? payImg[0] : payImg[1]" alt="">
                微信支付
            </div>
            <div :class="payIndex == 1 ? 'pay_btn1 pay_btn2 pay_btn3' : 'pay_btn1 pay_btn2'" @click="chang(1)">
                <img :src="payIndex == 1 ? payImg[2] : payImg[3]" alt="">
                支付宝支付
            </div>
        </div>

        <!-- 价格 -->
        <div class="vip_price1">
            <div :class="priceIndex == i ? 'vip_price2 vip_price2_1' : 'vip_price2'" v-for="(item, i) in priceList"
                :key="i" @click="changprice(i)">
                <div class="vip_price3">{{ item.typeName }}</div>
                <div class="vip_price4">约￥{{ item.dayPrice }}/ {{ item.type == 'perpetual' ? '天' : '月' }} </div>
                <div class="vip_price5">￥{{ item.price }} <span>/{{ item.type == 'perpetual' ? '终身' : item.type ==
                    'year' ? '年' : '月' }}</span></div>
                <div class="vip_price6">￥{{ item.originalCost }} <span>/{{ item.type == 'perpetual' ? '终身' : item.type == 'year' ? '年' : '月'
                        }} </span></div>
                <div v-if="item.type == 'perpetual'" class="vip_price8">限时</div>
                <div v-if="item.type == 'perpetual'" class="vip_price9">
                    <div>大佬</div>
                    <div>专用</div>
                </div>
            </div>
        </div>

        <div class="vip_price10" v-if="priceList.length">
            <div class="vip_price11">-￥{{ priceList[priceIndex].coupon }}</div>
            <div class="vip_price12">限时专享特惠</div>
        </div>
        <div class="vip_price7" @click="toPay">立即开通</div>


        <!-- 支付弹框 -->
        <div class="vip_pay" @click="vipPayShow = false" v-if="vipPayShow">
            <div class="vip_pay1" @click.stop="vipPayShow = true">
                <div class="vip_pay2">
                    扫码支付
                    <img @click.stop="vipPayShow = false" src="../assets/image/close.png" alt="">
                </div>
                <div class="vip_pay3">
                    <div class="vip_pay4">扫码支付 <span>￥ {{ priceList[priceIndex].price }} /终身</span></div>
                    <div class="vip_pay5">
                        <img v-if="payIndex == 0" :src="QrCode" alt="">
                        <iframe v-else :srcdoc="alipayUrl" :width="200" :height="200" frameborder="no" scrolling="no">
                        </iframe>
                        <div v-if="!agreement" class="vip_pay8">
                            <div class="vip_pay9">支付前请阅读 《会员服务协议》</div>
                            <div class="vip_pay10" @click="agreement = true">同意协议</div>
                        </div>
                    </div>
                    <div class="vip_pay6">
                        <img :src="payIndex == 0 ? payImg[0] : payImg[2]" alt="">
                        {{ payIndex == 0 ? '微信支付' : '支付宝支付' }}
                    </div>
                    <div class="vip_pay7">
                        <img :src="agreement ? require('@/assets/image/login4.png') : require('@/assets/image/login3.png')"
                            @click="agreement = !agreement" alt="">
                        <div @click="toagreement">《会员服务协议》</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//二维码
import QRCode from 'qrcode'
import data from '@/assets/data';
import api from "@/api/api";
export default {
    created() {
        this.getViplist()
    },
    data() {
        return {
            payImg: data.data.payImg,
            priceList: [],
            payIndex: 0, //支付方式下标
            priceIndex: 0,//价格下标
            vipPayShow: false, //支付购买弹框
            agreement: false, //同意会员协议
            alipayUrl: '', //支付宝二维码
            QrCode: '', // 微信二维码
            erwwidth: '', // 支付宝二维码宽度
        }
    },
    methods: {
        // 获取vip价格
        getViplist() {
            api.getViplist().then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.priceList = res.data.reverse()
                }
            });
        },
        // 切换支付方式
        chang(i) {
            this.payIndex = i
        },
        // 切换价格
        changprice(i) {
            this.priceIndex = i
        },
        toPay() {
            api.payVip({
                vipType: this.priceList[this.priceIndex].type,
                payType: this.payIndex == 0 ? 'wx' : 'zfb'
            }).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    if (this.payIndex == 1) {
                        this.alipayUrl = res.data.zfbParam
                        this.vipPayShow = true
                        this.erwwidth = 10 / 128 * document.documentElement.clientWidth
                        this.vipStatus(res.data.tradeNo)
                        // console.log(this.erwwidth)
                    } else if (this.payIndex == 0) {
                        this.createQR(res.data.codeUrl)
                        this.vipStatus(res.data.tradeNo)
                    }
                } else if (res.code == 401) {
                    this.$emit('login')
                } else {
                    this.$message({
                        message: res.msg,
                        type: "warning",
                    });
                }
            })
        },
        createQR(codeUrl) {
            let opts = {
                errorCorrectionLevel: "H",//容错级别type:"image/png",//生成的二维码类型
                quality: 0.3,//二维码质量
            }
            QRCode.toDataURL(codeUrl, opts, (er, url) => {
                this.QrCode = url
                this.vipPayShow = true
            })
        },
        vipStatus(tradeNo) {
            let setIntervalId = setInterval(() => {
                if (!this.vipPayShow) {
                    clearInterval(setIntervalId)
                }
                api.vipStatus(tradeNo).then((res) => {
                    if (res.code == 200 && res.data == 1) {
                        this.vipPayShow = false
                        this.$alert('支付成功', '支付结果', {
                            confirmButtonText: '确定',
                            callback: action => {
                                window.location.reload();
                            }
                        });
                        clearInterval(setIntervalId)
                    }
                })
            }, 1000);
        },
        toagreement() {
            window.open("https://ai.hbsry.com/protocol/ysxy.html");
        },
    }
}
</script>

<style>
.pay_btn {
    width: 20rem;
    margin: 0 auto;
    display: flex;
}

.pay_btn1 {
    width: 10rem;
    height: 2.5rem;
    border-radius: 2rem 0 0 2rem;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    color: #111111;
    cursor: default;
}

.pay_btn1 img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.8rem;
}

.pay_btn2 {
    border-radius: 0 2rem 2rem 0;
}

.pay_btn3 {
    background: linear-gradient(90deg, #FDEEB8 0%, #FFFFFF 59%, #FED185 100%);
}

.vip_price1 {
    width: 65rem;
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
}

.vip_price2 {
    width: 18rem;
    padding: 1rem;
    border: 2px solid #FFFFFF;
    border-radius: 1rem;
    background: url('../assets/image/vip_bg1.png') no-repeat;
    background-size: 100%;
    background-color: #FFFFFF;
    position: relative;
    cursor: default;
}

.vip_price2_1 {
    border: 2px solid #F0CD99;
}

.vip_price3 {
    font-weight: bold;
    font-size: 2.2rem;
    color: #FFC554;
    text-align: center;
    margin-top: 1.2rem;
}

.vip_price4 {
    text-align: center;
    font-size: 2.2rem;
    color: #333333;
    margin-top: 1rem;
}

.vip_price5 {
    color: #FF2020;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-top: 1rem;
}

.vip_price5 span {
    color: #333333;
    font-weight: 400;
}

.vip_price6 {
    font-size: 1rem;
    color: #FF9431;
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: #333333;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.vip_price6 span {
    color: #333333;
    font-weight: 400;
}

.vip_price7 {
    width: 30rem;
    height: 2.8rem;
    border-radius: 0.5rem;
    text-align: center;
    line-height: 2.8rem;
    color: #000000;
    font-size: 1.1rem;
    background: linear-gradient(90deg, #F9D6A8 0%, #F0B675 100%);
    box-shadow: 0px 4px 16px 0px rgba(185, 164, 122, 0.32);
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    cursor: default;
}

.vip_price8 {
    position: absolute;
    top: 0.8rem;
    left: 1.8rem;
    width: 3.2rem;
    height: 2.5rem;
    text-align: center;
    line-height: 2rem;
    color: #FFFFFF;
    font-size: 1rem;
    background: url('../assets/image/vip_bg2.png') no-repeat;
    background-size: 100% 100%;
}

.vip_price9 {
    position: absolute;
    top: -1.3rem;
    right: -2.8rem;
    width: 6rem;
    height: 6rem;
    background: url('../assets/image/vip_bg3.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 0.6rem;
    padding-left: 1.6rem;
    color: #FFFFFF;
    font-size: 1rem;
}

.vip_price10 {
    width: 65rem;
    height: 9rem;
    background: url('../assets/image/vip_bg6.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.vip_price11 {
    color: #FF2020;
    font-size: 2rem;
    font-weight: 700;
    padding-left: 7rem;
    width: 17rem;
}

.vip_price12 {
    color: #795621;
    font-size: 2.3rem;
    font-weight: 700;
    padding-left: 10rem;
}

.vip_pay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000038;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip_pay1 {
    width: 340px;
    height: 340px;
    background-color: #FFFFFF;
    border-radius: 0.6rem;
}

.vip_pay2 {
    width: 340px;
    height: 30px;
    color: #333333;
    font-size: 15px;
    background-color: #FFE8BD;
    border-radius: 0.6rem 0.6rem 0 0;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vip_pay2 img {
    width: 20px;
}

.vip_pay3 {
    width: 340px;
    height: 310px;
    background: url("../assets/image/vip_bg.png") no-repeat;
    background-size: 100% 100%;
    border-radius: 0 0 0.6rem 0.6rem;
    padding-top: 10px;
}

.vip_pay4 {
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    /* margin-top: 1rem; */
}

.vip_pay4 span {
    color: #FF2020;
}

.vip_pay5 {
    width: 200px;
    height: 200px;
    margin: 8px auto;
    display: flex;
    justify-content: center;
    /* background-color: #409EFF; */
    position: relative;
}

.vip_pay6 {
    width: 320px;
    display: flex;
    justify-content: center;
    font-size: 15px;
    color: #000000;
    margin-top: 8px;
}

.vip_pay6 img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.vip_pay7 {
    width: 100%;
    display: flex;
    font-size: 15px;
    color: #795621;
    align-items: center;
    justify-content: center;
    margin-top: 0.6rem;
    cursor: default;
}

.vip_pay7 img {
    width: 20px;
    height: 20px;
}

.vip_pay8 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #0000003f;
    color: #FFFFFF;
    font-size: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 20px;
    cursor: default;
}

.vip_pay9 {
    width: 80%;
    text-align: center;
}

.vip_pay10 {
    width: 80%;
    height: 30px;
    font-size: 15px;
    color: #000000;
    text-align: center;
    line-height: 30px;
    background: linear-gradient(90deg, #F9D6A8 0%, #F0B675 100%);
    border-radius: 2rem;
    margin-top: 5px;
    cursor: default;

}
</style>