<template>
    <div id="history">
        <div class="history_new" @click="newdialogue">
            <img src="../assets/image/new_icon.png" alt="">
            新建对话
        </div>

        <div class="history1">
            <div>历史记录</div>
            <img :src="alldel ? icon[1] : icon[0]" @click="delhistory" alt="">
        </div>
        <div class="history7" v-if="alldel">
            <div class="history8" @click="choiceAll">
                <img :src="choiceallid ? icon[3] : icon[2]" alt="">
                全选
            </div>
            <div class="history8" @click="del">
                <img :src="icon[4]" alt="">
                删除
            </div>
        </div>

        <div class="history2" ref="scrollContainer" @scroll="handleScroll">
            <Nodata v-if="hisList.length == 0" width="80" :notext="notext"></Nodata>
            <div v-else>
                <div :class="historyIndex == i ? 'history4' : 'history3'" v-for="(item, i) in hisList" :key="i"
                    @click="choice(i, item.id)">
                    <img v-if="alldel" class="history6" :src="item.del ? icon[3] : icon[2]"
                        @click.stop="choiceDel(item.id)" alt="">
                    <img :src="item.adviserIcon" alt="">
                    <div class="history5">{{ item.title }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import data from '@/assets/data';
import Nodata from './nodata.vue';
import api from "@/api/api";
export default {
    components: {
        Nodata
    },
    created() {
        this.sessionList()
    },
    data() {
        return {
            icon: data.data.historyBtn,
            notext: ['暂无数据'],
            alldel: false, //全选删除显示隐藏
            choiceallid: false, //全选id
            historyIndex: -1,//选中历史下标
            hisList: [], //历史记录列表
            pageNum: 1, // 页码
            stopload: false, //触底是否请求
        }
    },
    methods: {
        // 创建新对话
        newdialogue() {
            this.historyIndex = -1
            this.$emit('newdialogue','new')
        },
        // 获取历史记录
        sessionList() {
            api.sessionList({
                pageNum: this.pageNum,
                pageSize: 30
            }).then((res) => {
                // console.log(res)
                if (res.code == 200) {
                    this.hisList.push(...res.data)
                    if (res.data.length == 30) {
                        this.pageNum++
                    } else {
                        this.stopload = true
                    }
                }
            });
        },
        // 触底加载
        handleScroll() {
            const scrollContainer = this.$refs.scrollContainer;
            const { scrollTop, clientHeight, scrollHeight } = scrollContainer;
            if (scrollTop + clientHeight + 1 >= scrollHeight) {
                if (this.stopload) {
                    this.$message({
                        message: '暂无更多数据',
                        type: 'warning'
                    });
                    return
                }
                this.sessionList()
            }
        },
        // 选择历史记录
        choice(i, id) {
            if (this.alldel) {
                this.choiceDel(id)
                // 判断全选
                if (this.hisList.every(item => item.del === true)) {
                    this.choiceallid = true
                } else {
                    this.choiceallid = false
                }
                return
            }
            this.historyIndex = i
            this.$emit('hisDetail',{id:id,icon:this.hisList[i].adviserIcon})
        },
        // 删除历史记录显示隐藏
        delhistory() {
            this.alldel = !this.alldel
            this.hisList = this.hisList.map(item => ({ ...item, del: false }))
        },
        // 选择删除记录
        choiceDel(id) {
            this.hisList = this.hisList.map(item => {
                if (id == item.id) {
                    item.del = !item.del
                }
                return item
            })
        },
        // 全选
        choiceAll() {
            this.choiceallid = !this.choiceallid
            this.hisList = this.hisList.map(item => {
                item.del = this.choiceallid
                return item
            })
        },
        // 删除
        del() {
            if (!this.hisList.some(item => item.del)) {
                this.$message({
                    type: 'warning',
                    message: '请选择删除内容！'
                })
                return
            }
            this.$confirm('此操作将永久删除该对话记录, 是否继续?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let ids = this.hisList.filter(item => item.del).map(item => item.id)


                // console.log('删除--', ids)
                this.deleteSession(ids)

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 多选删除
        deleteSession(ids) {
            api.deleteSession({
                ids: ids.join(',')
            }).then((res) => {
                if (res.code == 200) {
                    this.hisList = this.hisList.filter(item => !item.del)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            });

        }
    }
}
</script>

<style>
#history {
    width: 14rem;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 0 0.8rem 0.8rem 0;
    padding: 1rem;
}

.history_new {
    width: 12rem;
    height: 2.5rem;
    color: #409EFF;
    font-size: 1rem;
    border: 1px solid #409EFF;
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.history_new img {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
}

.history1 {
    width: 12rem;
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
    font-weight: 700;
    margin: 1rem 0;
}

.history1 img {
    width: 2rem;
}

.history2 {
    width: 12rem;
    height: 72vh;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
}


.history3 {
    width: 12rem;
    padding: 0.5rem;
    cursor: default;
    display: flex;
    align-items: center;
    margin: 0.1rem 0;
    /* justify-content: space-between; */
}

.history3:hover {
    background: #FFFFFF;
    background-image: url('../assets/image/history_icon2.png');
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    padding-right: 2rem;
    background-position: 92% center;
    border-radius: 2rem;
}

.history4 {
    background: #DCEBFF;
    border-radius: 2rem;
    width: 12rem;
    font-size: 1rem;
    color: #0085FF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.5rem;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.1rem 0;
}

.history5 {
    width: 8.5rem;
    font-size: 1.13rem;
    color: #111111;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
}

.history3 img,
.history4 img {
    width: 2rem;
    margin-right: 0.4rem;
}

.history2 .history6 {
    width: 1.2rem;
}

.history7 {
    display: flex;
    justify-content: space-between;
    background-color: #E8E8E8;
    width: 12rem;
    height: 2rem;
    padding: 0.5rem 0;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
}

.history8 {
    width: 6rem;
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.history8:first-child {
    border-right: 1px solid #999999;
}

.history8 img {
    width: 1.1rem;
    margin-right: 0.5rem;
    margin-top: 0.1rem;
}
</style>