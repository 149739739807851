<template>
    <div class="vip_major15">
        <div class="vip_major16">
            <div class="vip_major17">
                <div class="vip_major18">
                    <img src="../assets/image/vip_major5.png" alt="">
                    VIP套餐选择
                </div>
                <div class="vip_major19">APP/PC端/小程序三端互通，<span @click="todownload">点击前往></span></div>
            </div>

            <div class="vip_major20">
                <div :class="priceIndex == i ? 'vip_major21' : 'vip_major21 vip_major22'" v-for="(item, i) in priceList"
                    :key="i" @click="changprice(i)">
                    <div :class="priceIndex == i ? 'vip_major23' : 'vip_major23 vip_major23_1'">{{ item.typeName }}
                    </div>
                    <div :class="priceIndex == i ? 'vip_major24' : 'vip_major24 vip_major24_1'">约￥{{ item.type == 'perpetual' ? item.dayPrice : item.monthly }}/
                        {{ item.type == 'perpetual' ? '天' : '月' }}</div>
                    <div :class="priceIndex == i ? 'vip_major25' : 'vip_major25 vip_major25_1'">￥{{ item.price }}
                        <span>/{{ item.type == 'perpetual' ? '终身' : item.type ==
                            'year' ? '年' : '月' }}</span></div>
                    <div :class="priceIndex == i ? 'vip_major26' : 'vip_major26 vip_major25_1'">￥{{ item.originalCost }}
                        <span>/{{ item.type == 'perpetual' ? '终身' : item.type == 'year' ? '年' : '月'
                            }} </span></div>
                    <img v-if="i == 0" class="vip_major27" src="../assets/image/vip_major3.png" alt="">
                    <img v-if="i == 0" class="vip_major28" src="../assets/image/vip_major4.png" alt="">
                </div>
            </div>

            <div class="vip_major29">
                <div class="vip_major30">
                    <img src="../assets/image/vip_major6.png" alt="">
                    限时专享特惠
                </div>
                <div v-if="priceList.length" class="vip_major31">-￥{{ priceList[priceIndex].coupon }}</div>
            </div>

            <div class="vip_major32" v-if="priceList.length">
                <div class="vip_major33">
                    <div class="vip_major34">
                        <div class="vip_major34_1">￥{{ priceList[priceIndex].price }}</div>/{{
                            priceList[priceIndex].type == 'perpetual' ? '终身' : priceList[priceIndex].type == 'year' ? '年' :
                        '月' }}
                        <div class="vip_major34_2">约￥{{ priceList[priceIndex].type == 'perpetual' ?  priceList[priceIndex].dayPrice : priceList[priceIndex].monthly  }}/{{ priceList[priceIndex].type
                            == 'perpetual' ? '天' : '月'}}</div>
                    </div>
                    <div class="vip_major35">支付即视为您同意<span @click="toagreement">《会员服务协议》</span></div>
                </div>
                <div class="vip_major36" @click="toPay(0)">
                    点击支付
                </div>
            </div>
        </div>


        <!-- 支付弹框 -->
        <div class="vip_pay" @click="vipPayShow = false" v-if="vipPayShow && priceList.length">
            <div class="vip_pay1" @click.stop="vipPayShow = true">
                <div class="vip_pay2">
                    扫码支付
                    <img @click.stop="vipPayShow = false" src="../assets/image/close.png" alt="">
                </div>
                <div class="vip_pay3">
                    <div class="vip_pay4">扫码支付 <span>￥ {{ priceList[priceIndex].price }} /终身</span></div>
                    <div class="vip_pay5">
                        <img v-if="payIndex == 1" :src="QrCode" alt="">
                        <iframe v-else :srcdoc="alipayUrl" :width="200" :height="200" frameborder="no" scrolling="no">
                        </iframe>
                    </div>
                    <div class="vip_pay6">
                        <div class="vip_pay6_1" @click="toPay(0)">
                            <img :src="payImg[2]" alt="">
                            支付宝支付
                            <img :src="payIndex == 0 ? require('@/assets/image/login4.png') : require('@/assets/image/login3.png')" alt="">
                        </div>
                        <div class="vip_pay6_1" @click="toPay(1)">
                            <img :src="payImg[0]" alt="">
                            微信支付
                            <img :src="payIndex == 1 ? require('@/assets/image/login4.png') : require('@/assets/image/login3.png')" alt="">
                        </div>
                    </div>
                    <div class="vip_pay7">
                        <div >支付即视为您同意<span @click="toagreement">《会员服务协议》</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//二维码
import QRCode from 'qrcode'
import api from "@/api/api";
import data from "@/assets/data";
export default {
    data() {
        return {
            payImg: data.data.payImg,
            priceList: [],
            payIndex: 0, //支付方式下标
            priceIndex: 0, //价格下标
            vipPayShow: false, //支付购买弹框
            alipayUrl: '', //支付宝二维码
            QrCode: '', // 微信二维码
        }
    },
    created() {
        this.getViplist()
    },
    methods: {
        // 获取vip价格
        getViplist() {
            api.getViplist().then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.priceList = res.data.reverse()
                }
            });
        },
        //去下载
        todownload() {
            this.$emit('todownload')
        },
        // 切换价格
        changprice(i) {
            this.priceIndex = i
        },
        toPay(i) {
            this.payIndex = i
            api.payVip({
                vipType: this.priceList[this.priceIndex].type,
                payType: i == 0 ? 'zfb' : 'wx'
            }).then((res) => {
                // console.log(res)
                if (res.code == 200) {
                    if (this.payIndex == 0) {
                        this.alipayUrl = res.data.zfbParam
                        this.vipPayShow = true
                        this.vipStatus(res.data.tradeNo)
                    } else if (this.payIndex == 1) {
                        this.createQR(res.data.codeUrl)
                        this.vipStatus(res.data.tradeNo)
                    }
                } else if (res.code == 401) {
                    this.$emit('login')
                } else {
                    this.$message({
                        message: res.msg,
                        type: "warning",
                    });
                }
            })
        },
        createQR(codeUrl) {
            let opts = {
                errorCorrectionLevel: "H",//容错级别type:"image/png",//生成的二维码类型
                quality: 0.3,//二维码质量
            }
            QRCode.toDataURL(codeUrl, opts, (er, url) => {
                this.QrCode = url
                this.vipPayShow = true
            })
        },
        vipStatus(tradeNo) {
            let setIntervalId = setInterval(() => {
                if (!this.vipPayShow) {
                    clearInterval(setIntervalId)
                }
                api.vipStatus(tradeNo).then((res) => {
                    if (res.code == 200 && res.data == 1) {
                        this.vipPayShow = false
                        this.$alert('支付成功', '支付结果', {
                            confirmButtonText: '确定',
                            callback: action => {
                                window.location.reload();
                            }
                        });
                        clearInterval(setIntervalId)
                    }
                })
            }, 1000);
        },
        toagreement() {
            window.open("https://ai.hbsry.com/protocol/hyxy.html");
        },
    }
}
</script>

<style>
.vip_major15 {
    width: 56rem;
    height: 41rem;
    padding-top: 2rem;
    padding-left: 0.5rem;
}

.vip_major16 {
    width: 54rem;
    height: 38rem;
    margin: 0 auto;
    padding: 1.2rem;
    background: linear-gradient(315deg, #F9D9BE 0%, #FAF9FA 100%);
    border-radius: 1rem;
}

.vip_major17 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.vip_major18 {
    color: #795621;
    font-weight: bold;
    font-size: 1.33rem;
    display: flex;
    align-items: center;
}

.vip_major18 img {
    width: 1.2rem;
    margin-right: 0.5rem;
}

.vip_major19 {
    color: #111111;
    font-size: 1.2rem;
    cursor: default;
}

.vip_major19 span {
    color: #FC3E00;
}

.vip_major20 {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.vip_major21 {
    width: 16.5rem;
    padding-top: 1.2rem;
    border: 0.3rem solid #FF7315;
    border-radius: 0.5rem;
    background-color: #FFFFFF;
    cursor: default;
    position: relative;
}

.vip_major22 {
    width: 16.9rem;
    border: 0.1rem solid #F1D1A1;
    background-color: #FFF5EA;
}

.vip_major23 {
    color: #745000;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
}

.vip_major24 {
    color: #FF2020;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    margin: 1rem 0;
}

.vip_major25 {
    text-align: center;
    color: #FF8719;
    font-size: 1.33rem;
}

.vip_major26 {
    font-size: 1rem;
    color: #FFB134;
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: #333333;
    margin: 1rem 0;
}

.vip_major23_1 {
    color: #B38827;
}

.vip_major24_1 {
    color: #444444;
}

.vip_major25_1 {
    color: #555555;
}

.vip_major27 {
    width: 4.8rem;
    height: 2.9rem;
    position: absolute;
    top: -1.2rem;
    left: 1.5rem;
}

.vip_major28 {
    width: 5.4rem;
    height: 5.4rem;
    position: absolute;
    top: -2rem;
    right: -1rem;
}

.vip_major29 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #F58A86;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
}

.vip_major30 {
    font-size: 1.13rem;
    color: #795621;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.vip_major30 img {
    width: 2.8rem;
    margin-right: 0.5rem;
}

.vip_major31 {
    color: #FF2020;
    font-size: 1.46rem;
    font-weight: bold;
}

.vip_major32 {
    background: #FFFFFF;
    border-radius: 0.8rem;
    width: 100%;
    height: 13rem;
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip_major34 {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 1.6rem;
}

.vip_major34_1 {
    color: #FF8719;
}

.vip_major34_2 {
    color: #FF2020;
    font-weight: bold;
    margin-left: 0.5rem;
}

.vip_major35 {
    color: #000000;
    font-size: 1.133rem;
    margin-top: 0.5rem;
}

.vip_major35 span {
    color: #409EFF;
    cursor: default;
}

.vip_major36 {
    width: 16rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    border-radius: 3rem;
    background-color: #409EFF;
    font-size: 1.33rem;
    color: #FFFFFF;
    font-weight: 400;
    margin-left: 2rem;
    cursor: default;
}

.vip_pay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000038;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip_pay1 {
    width: 340px;
    height: 360px;
    background-color: #FFFFFF;
    border-radius: 0.6rem;
}

.vip_pay2 {
    width: 340px;
    height: 30px;
    color: #333333;
    font-size: 15px;
    background-color: #FFE8BD;
    border-radius: 0.6rem 0.6rem 0 0;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vip_pay2 img {
    width: 20px;
}

.vip_pay3 {
    width: 340px;
    height: 330px;
    background: url("../assets/image/vip_bg.png") no-repeat;
    background-size: 100% 100%;
    border-radius: 0 0 0.6rem 0.6rem;
    padding-top: 10px;
}

.vip_pay4 {
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    /* margin-top: 1rem; */
}

.vip_pay4 span {
    color: #FF2020;
}

.vip_pay5 {
    width: 200px;
    height: 200px;
    margin: 8px auto;
    display: flex;
    justify-content: center;
    /* background-color: #409EFF; */
    position: relative;
    margin-top: 12px;
}

.vip_pay6 {
    width: 340px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    font-size: 15px;
    color: #000000;
    margin-top: 25px;
}

.vip_pay6_1 {
    display: flex;
    align-items: center;
    cursor: default;
}
.vip_pay6_1 img{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.vip_pay7 {
    width: 100%;
    display: flex;
    font-size: 15px;
    color: #795621;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    cursor: default;
}

.vip_pay7 img {
    width: 20px;
    height: 20px;
}

</style>