<template>
    <div class="vip_bottom">
        <div class="vip_bottom1">
            <img src="../assets/image/vip_icon1.png" alt="">
            <div>APP/PC端/小程序三端互通，快来体验吧！</div>
        </div>
        <div class="vip_bottom2" @click="download">立即下载</div>

        <div class="download" @click="downloadShow = false" v-if="downloadShow">
            <div class="download1" @click.stop="loginPopup = true">
                <div class="download2">
                    软件下载
                    <img @click.stop="downloadShow = false" src="../assets/image/close.png" alt="">
                </div>
                <div class="download3">
                    <div class="download4">
                        <div :class="lableIndex == i ? 'download5' : 'download5 download6'" 
                        v-for="(item,i) in lable" :key="i"
                        @click.stop="choice(i)">
                            {{item}}
                        </div>
                    </div>

                    <div v-if="lableIndex == 0">
                        <div class="download7">
                            <img src="../assets/image/download.png" alt="">
                        </div>
                        <div class="download8">手机扫码二维码下载</div>
                    </div>

                    <div v-if="lableIndex == 1">
                        <div class="download7">
                            <img src="../assets/image/download1.png" alt="">
                        </div>
                        <div class="download8">微信扫描二维码</div>
                    </div>

                    <div class="download9" v-else>
                        持续更新中，敬请期待
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
    return {
        lable:['安卓端','小程序','IOS'],
        lableIndex:0,
        downloadShow:false,
    }
  },
    methods: {
        choice(i){
            this.lableIndex = i
        },
        download(){
            this.downloadShow = true
        }
    }
}
</script>

<style>
.vip_bottom {
    position: absolute;
    bottom: 0;
    width: 128rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30rem;
    background-color: #FFFFFF;
}

.vip_bottom1 {
    display: flex;
    color: #000000;
    font-size: 1rem;
    font-weight: 500;
}

.vip_bottom1 img {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1rem;
}

.vip_bottom2 {
    width: 7.5rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    color: #FFFFFF;
    font-size: 1rem;
    background: linear-gradient(144deg, #409EFF 0%, #A346FA 100%);
    border-radius: 0.5rem;
    cursor: default;
}
.download{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000038;
    display: flex;
    align-items: center;
    justify-content: center;
}
.download1{
    width: 22rem;
    height: 22rem;
    background-color: #FFFFFF;
    border-radius: 0.6rem;
}
.download2{
    width: 22rem;
    height: 2.2rem;
    color: #333333;
    font-size: 1rem;
    background-color: #FFE8BD;
    border-radius: 0.6rem 0.6rem 0 0;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.download2 img{
    width: 1.3rem;
}
.download3{
    width: 22rem;
    height: 19.8rem;
    background: url("../assets/image/vip_bg.png") no-repeat;
    background-size: 100% 100%;
    border-radius: 0 0 0.6rem 0.6rem;
}
.download4{
    display: flex;
    padding: 0 3rem;
    padding-top: 1.5rem;
    justify-content: space-between;
}
.download5{
    width: 5rem;
    height: 3rem;
    background-color: #F0B777;
    border-radius: 0.5rem;
    font-size: 1.3rem;
    font-weight: 700;
    color: #795621;
    text-align: center;
    line-height: 3rem;
    cursor: default;
}
.download6{
    background-color: #FFDDB7;
    color: #A19586;
}
.download7{
    width: 10rem;
    height: 10rem;
    margin: 1rem auto;
    margin-top: 1rem;
    /* background-color: #409EFF; */
}
.download7 img{
    width: 100%;
    height: 100%;
}
.download8{
    width: 22rem;
    text-align: center;
    color: #333333;
    font-size: 1.1rem;
}
.download9{
    width: 22rem;
    text-align: center;
    color: #333333;
    font-size: 1.1rem;
    margin-top: 4.5rem;
}
</style>