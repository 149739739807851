<template>
    <div class="dialogue">
        <!-- 示例 -->
        <div class="dialogue_left" v-if="cueword">
            <img :src="head" alt="">
            <div class="dialogue_left1" :style="{ maxWidth: max + '%' }">
                {{ cueword }}
            </div>
        </div>

        <div v-for="(item, i) in dialogueList" :key="i">
            <!-- 提问 -->
            <div class="dialogue_right">
                <div class="dialogue_right1" :style="{ maxWidth: max + '%' }">
                    {{ item.question }}
                </div>
                <img src="../assets/image/head.png" alt="">
            </div>

            <!-- 回答 -->
            <div class="dialogue_left">
                <img :src="head" alt="">
                <div class="dialogue_left1" :ref="'pdf' + i" :style="{ maxWidth: max + '%' }">
                    <div v-if="item.id != null" v-html="item.anwser"></div>
                    <Typewriter v-else ref="typewriter" :content="item.anwser" @anwserOver="anwserOver"></Typewriter>
                    <div class="dialogue_left2">
                        <div>(当前结果为AI合成，仅供参考)</div>
                        <el-popover  v-if="item.downloadShow"  placement="bottom" ref="popover_{{ i }}" trigger="click" class="dialogue_left3">
                            <div class="dialogue_left2_1">
                                <div class="dialogue_left2_2" @click="download(item.anwser, i,0)">Excel</div>
                                <div class="dialogue_left2_2" @click="download(item.anwser, i,1)">Word</div>
                            </div>
                            <img src="@/assets/image/download_icon.png" slot="reference" alt="">
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import Typewriter from './typewriter.vue';
export default {
    props: {
        max: {
            type: Number,
            default: 92.4
        },
        // 提示词
        cueword: {
            type: String,
            default: ''
        },
        // 头像
        head: {
            type: String,
            default: ''
        }
    },
    components: {
        Typewriter
    },
    data() {
        return {
            dialogueList: [],
            dIndex: -1,
        }
    },
    methods: {
        over() {
            this.$refs.typewriter[this.$refs.typewriter.length - 1].overtext()
        },
        getQuestion(data) {
            // console.log('对话记录', data)
            this.dialogueList = []
            this.dialogueList = data;
        },
        anwserOver() {
            this.$emit("anwserOver");
        },
        download(item, i,l) {
           if(l == 0){
            this.$emit('downloadE', { html: item, i: i })
           }else if(l ==1){
            this.$emit('downloadW', { html: item, i: i })
           }
        },
    }
}
</script>

<style>
.dialogue_left,
.dialogue_right {
    width: 100%;
    display: flex;

}

.dialogue_right {
    justify-content: end;
    margin: 1.6rem 0;
}

.dialogue_left img,
.dialogue_right img {
    width: 3rem;
    height: 3rem;
    margin-right: 1.2rem;
}

.dialogue_right img {
    margin-right: 0rem;
    margin-left: 1.2rem;
}

.dialogue_left1,
.dialogue_right1 {
    /* max-width: 92.4%; */
    background: #FFFFFF;
    font-size: 1.06rem;
    padding: 0.5rem 0.8rem;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    /* font-weight: 600; */
}

.dialogue_right1 {
    background: #409EFF;
    color: #FFFFFF;
    border-radius: 0.5rem 0 0.5rem 0.5rem;
}

.dialogue_left2 {
    margin-top: 1rem;
    border-top: 1px solid #dddddd;
    padding-top: 0.8rem;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.el-popover {
    min-width: 5.6rem !important;
    padding: 0 !important;
}

.dialogue_left2 img {
    width: 1.4rem;
    height: 1.4rem;
}

.dialogue_left2_1 {
    background-color: #FFFFFF;
    border-radius: 0.5rem;
}

.dialogue_left2_2 {
    height: 2.13rem;
    width: 5.6rem;
    line-height: 2.13rem;
    text-align: center;
    font-size: 1.06rem;
    color: #333333;
    margin: 0.2rem;
    cursor: pointer;
}

.dialogue_left2_2:hover {
    background-color: #E5E5E5;
}


</style>